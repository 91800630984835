import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'

import { AttractNavigator, ControlPanel, Carousel } from 'components'
import { WebSocketsProvider } from 'context'
import { formatAttractPage, collectionNames } from 'utils'

import video from '../videos/attract_video.mp4'
import * as styles from './index.module.scss'

const AttractPage = ({ data }) => {
	const updatedData = formatAttractPage(data)['inline']

	const [currentCollection, setCurrentCollection] = useState(updatedData[1])
	const [images, setCurrentImages] = useState([])

	const collectionUp = () => {
		if (currentCollection.id === collectionNames.length) {
			return
		}
		setCurrentCollection(prev => ({
			id: updatedData[prev.id + 1].id,
			prods: updatedData[prev.id + 1].prods
		}))
	}

	const collectionDown = () => {
		if (currentCollection.id === 1) {
			return
		}
		setCurrentCollection(prev => ({
			id: updatedData[prev.id - 1].id,
			prods: updatedData[prev.id - 1].prods
		}))
	}

	const navigationHandler = i =>
		navigate(
			`collection${currentCollection.prods[i].shelf_id}/` +
			`${currentCollection.prods[i].collection_id}?product_index=${i}`
		)

	useEffect(() => {
		const getImages = () => {
			return currentCollection.prods.map(product => ({
				...product.image,
				name: `${product.name} - Shelf #${product.shelf_id}`,
				shelf: `Shelf #${product.shelf_id}`
			}))
		}

		setCurrentImages(getImages())
	}, [currentCollection])

	return (
		<WebSocketsProvider>
			<ControlPanel />

			<div className={styles.container}>
				<div className={styles.attractContainer}>
					<AttractNavigator
						id={currentCollection.id}
						downAction={collectionDown}
						upAction={collectionUp}
						collectionNames={collectionNames}
					/>
					<div className={styles.carouselContainer}>
						<Carousel images={images} clickHandler={navigationHandler} />
					</div>
				</div>
			</div>
			<video className={styles.video} autoPlay loop muted>
				<source src={video} type='video/mp4' />
			</video>
		</WebSocketsProvider>
	)
}

export const query = graphql`
	query attractData {
		allProductsCsv {
			nodes {
				id
				name
				product_id
				collection_id
				collection_name
				asset_filename
				shelf_id
			}
		}
		allProductImages: allImageSharp {
			nodes {
				fluid {
					originalName
				}
				gatsbyImageData(
					placeholder: BLURRED
					layout: CONSTRAINED
					width: 210
					height: 210
				)
			}
		}
	}
`

export default AttractPage
